/*
	This mixin can be used to set the object-fit:
	@include object-fit(contain);

	or object-fit and object-position:
	@include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null){
	-o-object-fit: $fit;
	object-fit: $fit;

	@if $position {
		-o-object-position: $position;
		object-position: $position;
		font-family: 'object-fit: #{$fit}; object-position: #{$position}';
	} @else {
		font-family: 'object-fit: #{$fit}';
	}
}

// Set user select property for an element
@mixin userSelect($value)
{
	-webkit-user-select: $value;
	-moz-user-select: $value;
	-ms-user-select: $value;
} 

// Modify css for text selection
@mixin textSelection
{
	&::selection      { @content; }
	&::-moz-selection { @content; }
}

// Style placeholders within input fields
@mixin placeholder
{
	&::-webkit-input-placeholder { @content }
	&::-moz-placeholder          { @content }
	&:-moz-placeholder           { @content }
	&:-ms-input-placeholder      { @content }
}

// Add CSS transition to any element
@mixin transition($properties...)
{
	@if length($properties) >= 1 {

		-webkit-transition: $properties;
		-moz-transition:    $properties;
		-ms-transition:     $properties;
		-o-transition:      $properties;
		transition:         $properties;
	}

	@else {

		-webkit-transition: all 0.2s ease-in-out 0s;
		-moz-transition:    all 0.2s ease-in-out 0s;
		-ms-transition:     all 0.2s ease-in-out 0s;
		-o-transition:      all 0.2s ease-in-out 0s;
		transition:         all 0.2s ease-in-out 0s;
	}
}

/* .box { @include transition(width, height 0.3s ease-in-out); } */

// Add border radius to an element
@mixin border-radius($value)
{
	-webkit-border-radius: $value;
	-moz-border-radius:    $value;
	-ms-border-radius:     $value;
	border-radius:         $value;
}

/* .box { @include border-radius(10px); } */

@mixin triangle($direction, $size: 6px, $color: #222)
{
	content: '';
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	@if ($direction == 'up'){
		border-bottom: $size solid $color;
		border-left: 1/2*$size solid transparent;
		border-right: 1/2*$size solid transparent;
	}
	@else if ($direction == 'down'){
		border-top: $size solid $color;
		border-left: 1/2*$size solid transparent;
		border-right: 1/2*$size solid transparent;
	}
	@else if ($direction == 'left'){
		border-top: 1/2*$size solid transparent;
		border-bottom: 1/2*$size solid transparent;
		border-right: $size solid $color;
	}
	@else if ($direction == 'right'){
		border-top: 1/2*$size solid transparent;
		border-bottom: 1/2*$size solid transparent;
		border-left: $size solid $color;
	}
}

@mixin retina
{
	@media
	only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min--moz-device-pixel-ratio: 2),
	only screen and (-o-min-device-pixel-ratio: 2/1),
	only screen and (min-device-pixel-ratio: 2),
	only screen and (min-resolution: 192dpi),
	only screen and (min-resolution: 2dppx) {
		@content;
	}
}