/* --------------------------------
   feedbacks
-------------------------------- */

.feedbacks
{
	&--style-1
	{
		color: $white;

		.feedback
		{
			&__author
			{
				&__name,
				&__position
				{
					color: inherit;
				}
			}
		}
	}

	&--style-2 { }
}

.feedback
{
	&__item
	{
		overflow: hidden;
		font: {
			weight: 600;
			family: $secondary-fontFamily;
		};
	}

	&__author
	{
		text-align: center;
		margin-bottom: 25px;

		&__photo
		{
			width: 140px;
			margin-bottom: 25px;
		}

		&__name,
		&__position
		{
			margin: 0;
		}

		&__name { font-weight: inherit; }

		&__position { font-size: rem-calc(12px); }
	}

	&__text
	{
		overflow: hidden;
		font-size: rem-calc(18px);
		text-align: center;
	}
}

@include min-screen($sm-width)
{
	.feedback
	{
		&__text { line-height: 1.3; }
	}
}

@include min-screen($md-width)
{
	.feedback
	{
		&__author
		{
			float: left;
			margin-bottom: 0;
		}

		&__text
		{
			font-size: rem-calc(20px);
			text-align: left;
			padding-left: 60px;
		}
	}
}

@include min-screen($lg-width)
{
	.feedback
	{
		&__text
		{
			font-size: rem-calc(25px);
			padding-left: 80px;
		}
	}
}

@include min-screen($xl-width)
{
	.feedback
	{
		&__text { padding-left: 100px; }
	}
}