%width-100  { width: 100%; }
%height-100 { height: 100%; }
%width-0  { width: 0; }
%height-0 { height: 0; }

/* display */
%display-none         { display: none; }
%display-block        { display: block; }
%display-table        { display: table; }
%display-table-cell   { display: table-cell; }
%display-inline-block { display: inline-block;}

/* position */
%pos-relative { position: relative; }
%pos-absolute { position: absolute; }

%block-absolute--full
{
	@extend %pos-absolute;
	top: 0;
	left: 0;
	@extend %width-100;
	@extend %height-100;
}

%bg-cover
{
	background: {
		position: 50% 50%;
		repeat: no-repeat;
		size: cover;
	}
}

%bg-ico
{
	background: {
		position: 50% 50%;
		repeat: no-repeat;
		size: 100%;
	}
}

/* float */
%fl-l { float: left; }
%fl-r { float: right; }

/* text align */
%text-center { text-align: center; }
%text-left   { text-align: left; }
%text-right  { text-align: right; }

/* vertical align */
%v-top    { vertical-align: top; }
%v-middle { vertical-align: middle; }
%v-bottom { vertical-align: bottom; }


%no-select-no-drag
{
	user-select: none;
	-webkit-user-drag: none;
	user-drag: none;
	-webkit-touch-callout: none;
	cursor: default;
}